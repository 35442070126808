import { appInsights } from 'AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

type LogLevel = 'all' | 'info' | 'debug' | 'error';

const __isInfoAllowed = Symbol('isInfoAllowed');
const __isDebugAllowed = Symbol('isInfoAllowed');
const __isErrorAllowed = Symbol('isErrorAllowed');
const __brandName = Symbol('brandName');
const __tenantName = Symbol('tenantName');

const data = {
  [__isInfoAllowed]: true,
  [__isDebugAllowed]: true,
  [__isErrorAllowed]: true,
  [__brandName]: '',
  [__tenantName]: '',
};

export default {
  setMetaData: (brandName: string, tenantName: string) => {
    data[__brandName] = brandName;
    data[__tenantName] = tenantName;
  },

  setLogLevels: (logLevels: LogLevel[]) => {
    data[__isInfoAllowed] = false;
    data[__isDebugAllowed] = false;
    data[__isErrorAllowed] = false;

    if (logLevels.includes('all')) {
      data[__isInfoAllowed] = true;
      data[__isDebugAllowed] = true;
      data[__isErrorAllowed] = true;
      return;
    }

    if (logLevels.includes('info')) {
      data[__isInfoAllowed] = true;
    }
    if (logLevels.includes('debug')) {
      data[__isDebugAllowed] = true;
    }
    if (logLevels.includes('error')) {
      data[__isErrorAllowed] = true;
    }
  },

  info: (eventName: string, props?: any) => {
    if (data[__isInfoAllowed]) {
      console.info(eventName, props);
      appInsights.trackEvent(
        {
          name: eventName,
          properties: {
            brandName: data[__brandName],
            tenantName: data[__tenantName],
          },
        },
        props
      );
    }
  },

  debug: (eventName: string, props?: any) => {
    if (data[__isDebugAllowed]) {
      console.debug(eventName, props);
      appInsights.trackEvent(
        {
          name: eventName,
          properties: {
            brandName: data[__brandName],
            tenantName: data[__tenantName],
          },
        },
        props
      );
    }
  },

  error: (error: Error) => {
    if (data[__isErrorAllowed]) {
      console.error(error);
      appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  },
};
