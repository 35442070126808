const DEFAULT_CACHE_EXPIRY_IN_MINUTE = 30; // in case data not set in env

// convert input to number, if fails then default to 5
// return milliseconds of that number
function secondsToMilliseconds(minute: any) {
  const minuteInt = parseInt(minute) || DEFAULT_CACHE_EXPIRY_IN_MINUTE;
  return minuteInt * 60 * 1000;
}

export const localCache = new Proxy(
  {},
  {
    set: (_: any, prop: string, value: any): any => {
      const now = new Date();
      const CACHE_EXPIRY = secondsToMilliseconds(value.expireIn);
      const item = {
        value,
        expiry: now.getTime() + CACHE_EXPIRY,
      };
      localStorage.setItem(prop, JSON.stringify(item));
      return true;
    },

    get: (_: any, prop: string) => {
      try {
        const rawData = localStorage.getItem(prop);

        // if no data in storage, return null
        if (!rawData) {
          return null;
        }

        // this will throw error in case the data is corrupted and an invalid JSON is parsed
        const data = JSON.parse(rawData);

        const now = new Date();
        if (now.getTime() > data.expiry) {
          // if the item is expired, delete the item from storage and return null
          localStorage.removeItem(prop);
          return null;
        }

        return data;
      } catch (error) {
        // in case an error occurred during parsing, remove the data from storage, return null
        localStorage.removeItem(prop);
        return null;
      }
    },

    deleteProperty: (_: any, prop: string) => {
      localStorage.removeItem(prop);
      return true;
    },
  }
);
