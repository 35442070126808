const LoadingOverlayExt = require('react-loading-overlay').default;

export const LoadingOverlay = (props: any) => (
  <LoadingOverlayExt
    spinner
    styles={{
        overlay: (base: any) => ({
            ...base,
            background: 'rgba(255, 99, 71, 0)',
          }),
      spinner: (base: any) => ({
        ...base,
        '& svg circle': {
          stroke: '#002f52',
          strokeWidth: 3,
        },
      }),
      content: (base: any) => ({
        ...base,
        fontSize: '20px',
        fontFamily: 'Arial',
        color: '#002f52',
      }),
    }}
    {...props}
  />
);