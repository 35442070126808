import React from 'react';

import { localCache } from 'localCache';
import Logger from './utils/logger';

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // remove any cache that was set so new data is loaded on refresh
    delete localCache.brand;

    // we also log the error to an error reporting service
    Logger.error(error);
  }

  render() {
    if (this.state.hasError) {
      // We can render any custom fallback UI
      return (
        <div className="App">
          <div className="App-header" style={{ color: 'black' }}>
            Something went wrong.
            <br />
            Please try again later.
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
