import React, { useContext } from 'react';
import { getURLParamsFromLocation } from 'common/utils/location';
import { RouteComponentProps } from 'react-router-dom';
import { BrandDataContext } from 'common/context/brandContext';
import { validateIdToken } from 'common/utils/oidc';
import Logger from 'common/utils/logger';

export const RedirectPage = React.memo((props: RouteComponentProps<{ clientId: string }>) => {
  const brandData = useContext<any>(BrandDataContext);
  const urlQueryParams = getURLParamsFromLocation(props.location);

  const accessToken = urlQueryParams.get('access_token');
  const idToken = urlQueryParams.get('id_token');

  const clientId = props.match.params?.clientId;

  if (!clientId) {
    window.location.replace('/');
    return null;
  }

  if (accessToken && idToken && validateIdToken(idToken, brandData)) {
    const initiatorLoginUrls = brandData.brand_metadata?.initiator_login_urls;
    if (initiatorLoginUrls?.[clientId]) {
      Logger.debug('user redirected', { destination: initiatorLoginUrls[clientId] });
      window.location.replace(initiatorLoginUrls[clientId]);
      return null;
    }
  }

  window.location.replace('/');
  return null;
});
