import { RouteComponentProps } from 'react-router';

export const getURLParamsFromLocation = (location: RouteComponentProps['location']) => {
  // query params is found in location.hash (it mostly starts with #)
  if (location?.hash) {
    if (location.hash?.startsWith('#')) {
      return new URLSearchParams(location.hash.substring(1));
    } else {
      // In case it doesn't start with #
      return new URLSearchParams(location.hash);
    }
  } else {
    // In case it is found in search param
    return new URLSearchParams(location.search);
  }
};

export const extractKeyFromLocation = (location: RouteComponentProps['location'], key: string) => {
  // access_token is found in location.hash (it mostly starts with #)
  if (location?.hash) {
    if (location.hash?.startsWith('#')) {
      return new URLSearchParams(location.hash.substring(1)).get(key);
    } else {
      // In case it doesn't start with #
      return new URLSearchParams(location.hash).get(key);
    }
  } else {
    // In case it is found in search param
    return new URLSearchParams(location.search).get(key);
  }
};
