import axios from 'axios';
import { getOidcUrl } from 'common/utils/oidc';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.headers = {
  'Content-Type': 'application/json',
};

let responseInterceptor: any;

export const setUpAxiosClientIdHeader = (clientId: string) => {
  axios.defaults.headers.client_id = clientId;
};

export const setUpAxiosAuthHeader = (accessToken: string) => {
  axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

export const setUpResponseInterceptor = (brandData: any) => {
  responseInterceptor = axios.interceptors.response.use(
    res => res,
    err => {
      if (err.response.status === 401) {
        const oidcUrl = getOidcUrl(brandData, window.location.pathname);
        // Initiate the OIDC flow
        window.location.replace(oidcUrl);
      }
      throw err;
    }
  );
};

export const tearDownAxiosConfig = () => {
  delete axios.defaults.headers.client_id;
  delete axios.defaults.headers.Authorization;
  axios.interceptors.response.eject(responseInterceptor);
};

export default axios;
